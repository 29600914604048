import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_STATUS } from '@app/store/order';

@Pipe({
    name: 'paymentStatusIcon',
})
export class PaymentStatusIconPipe implements PipeTransform {
    transform(status: PAYMENT_STATUS | null | undefined): string {
        switch (status) {
            case PAYMENT_STATUS.PENDING:
            case PAYMENT_STATUS.OK:
                return 'hourglass-split';
            case PAYMENT_STATUS.COMPLETED:
            case PAYMENT_STATUS.PAID:
                return 'check-circle';
            case PAYMENT_STATUS.CANCELLED:
                return 'x-circle';
            default:
                return '';
        }
    }
}
